import classNames from 'classnames';
import type { ReactNode } from 'react';

import { Icon, type IconName } from '../Icon';

import styles from './Alert.module.css';

type AlertAppearance = 'info' | 'success' | 'warning' | 'error';

const APPEARANCE_STYLES = {
  info: styles.info,
  success: styles.success,
  warning: styles.warning,
  error: styles.error,
};

const APPEARANCE_ICON: Record<AlertAppearance, IconName> = {
  info: 'info_italic',
  success: 'done',
  warning: 'warning',
  error: 'warning',
};

export interface AlertProps {
  appearance?: AlertAppearance;
  className?: string;
  children?: ReactNode;
}

export const Alert = ({
  children,
  appearance = 'info',
  className,
}: AlertProps) => (
  <div
    className={classNames(
      className,
      styles.root,
      APPEARANCE_STYLES[appearance],
    )}
    role="alert"
  >
    <Icon
      className={styles.icon}
      size={20}
      name={APPEARANCE_ICON[appearance]}
    />
    <div className={styles.body}>{children}</div>
  </div>
);
