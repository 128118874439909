import Fallback, { type PageProps } from 'keycloakify/login';
import { lazy, Suspense } from 'react';

import { Theme, ThemeProvider } from '@neondatabase/glow';
import '@neondatabase/glow/theme.css';

import { AnalyticsProvider } from './analytics';
import { useI18n } from './i18n';
import './KcApp.css';
import type { KcContext } from './kcContext';
import { Template } from './Template';

const Login = lazy(async () => {
  const module = await import('./pages/Login');
  return { default: module.Login };
});
const Register = lazy(async () => {
  const module = await import('./pages/Register');
  return { default: module.Register };
});
const Error = lazy(async () => {
  const module = await import('./pages/Error');
  return { default: module.Error };
});
const Info = lazy(async () => {
  const module = await import('./pages/Info');
  return { default: module.Info };
});
const LoginPageExpired = lazy(async () => {
  const module = await import('./pages/LoginPageExpired');
  return { default: module.LoginPageExpired };
});
const LoginResetPassword = lazy(async () => {
  const module = await import('./pages/LoginResetPassword');
  return { default: module.LoginResetPassword };
});
const LoginVerifyEmail = lazy(async () => {
  const module = await import('./pages/LoginVerifyEmail');
  return { default: module.LoginVerifyEmail };
});
const LoginUpdatePassword = lazy(async () => {
  const module = await import('./pages/LoginUpdatePassword');
  return { default: module.LoginUpdatePassword };
});

// This is like adding classes to theme.properties
const classes: PageProps['classes'] = {};

export function KcApp(props: { kcContext: KcContext }) {
  const { kcContext } = props;

  const i18n = useI18n({ kcContext });

  const { segmentKey, gtmKey } = kcContext.properties ?? {};

  if (i18n === null) {
    // NOTE: Text resources for the current language are still being downloaded, we can't display anything yet.
    // We could display a loading progress but it's usually a matter of milliseconds.
    return null;
  }

  return (
    <Suspense fallback={null}>
      <ThemeProvider theme={Theme.Dark}>
        <AnalyticsProvider segmentKey={segmentKey!} gtmKey={gtmKey!}>
          {(() => {
            switch (kcContext.pageId) {
              case 'login.ftl':
                return (
                  <Login
                    {...{ kcContext, i18n, Template, classes }}
                    doUseDefaultCss
                  />
                );
              case 'register.ftl':
                return (
                  <Register
                    {...{ kcContext, i18n, Template, classes }}
                    doUseDefaultCss
                  />
                );
              case 'login-reset-password.ftl':
                return (
                  <LoginResetPassword
                    {...{ kcContext, i18n, Template, classes }}
                    doUseDefaultCss
                  />
                );
              case 'login-verify-email.ftl':
                return (
                  <LoginVerifyEmail
                    {...{ kcContext, i18n, Template, classes }}
                    doUseDefaultCss
                  />
                );
              case 'login-update-password.ftl':
                return (
                  <LoginUpdatePassword
                    {...{ kcContext, i18n, Template, classes }}
                    doUseDefaultCss
                  />
                );
              case 'error.ftl':
                return (
                  <Error
                    {...{ kcContext, i18n, Template, classes }}
                    doUseDefaultCss
                  />
                );
              case 'info.ftl':
                return (
                  <Info
                    {...{ kcContext, i18n, Template, classes }}
                    doUseDefaultCss
                  />
                );
              case 'login-page-expired.ftl':
                return (
                  <LoginPageExpired
                    {...{ kcContext, i18n, Template, classes }}
                    doUseDefaultCss
                  />
                );
              default:
                return (
                  <Fallback
                    {...{ kcContext, i18n, classes }}
                    Template={Template}
                    doUseDefaultCss
                  />
                );
            }
          })()}
        </AnalyticsProvider>
      </ThemeProvider>
    </Suspense>
  );
}
