import { AnalyticsBrowser } from '@segment/analytics-next';
import {
  type FC,
  memo,
  type ReactNode,
  useLayoutEffect,
  useMemo,
  useRef,
} from 'react';
import { useState } from 'react';

import type { UserData } from '@neondatabase/console-admin-api';

import {
  AnalyticsContext,
  type CustomAnalyticsBrowserContextValue,
} from './context';
import { useEventCallback } from './hooks';

interface AnalyticsProviderProps {
  user?: UserData;
  enabled: boolean;
  section: string;
  pageMapper: { [key: string]: string };
  segmentKey?: string;
  children?: ReactNode;
}

export const AnalyticsProvider: FC<AnalyticsProviderProps> = memo(
  ({ children, user, enabled, section, pageMapper, segmentKey }) => {
    const analytics = useMemo(() => {
      if (segmentKey && enabled) {
        return AnalyticsBrowser.load({
          writeKey: segmentKey,
          cdnURL: 'https://analytics.neon.tech',
        });
      }

      if (process.env.NODE_ENV === 'production') {
        // eslint-disable-next-line
        console.warn('Segment key is not defined');
      }
      return undefined;
    }, [enabled, segmentKey]);

    /**
      "currentPageId" is not used directly, only through currentPageIdRef.
      And there is motivation behind it:
      We are setting currentPageId during "page_view" event using
      useLayoutEffect and sometimes want to use the updated pageId immediately
      in useEffect right after mounting in order to send any of *_shown events,
      but React updates context's value only after useEffect and *_shown events
      are sent as if they have page_id from previous page. Using pageId by ref
      is workaround for this problem, value in ref updates and visible to
      everybody immediately.
     */
    const [, setCurrentPageId] = useState<string | undefined>();
    const currentPageIdRef = useRef<string | undefined>();
    const initializedRef = useRef(false);
    const postponePromiseRef = useRef<Promise<void> | undefined>();

    const postponeInitialization = useEventCallback((promise) => {
      if (initializedRef.current) {
        return;
      }

      const newPromise = (postponePromiseRef.current || Promise.resolve())
        .then(() => promise)
        .then(() => {
          // if the last promise was resolved then set it to undefined
          if (postponePromiseRef.current === newPromise) {
            postponePromiseRef.current = undefined;
          }
        });
      postponePromiseRef.current = newPromise;
    });

    const enqueueAction = useEventCallback(async (callback: () => void) => {
      while (postponePromiseRef.current) {
        await postponePromiseRef.current;
      }
      initializedRef.current = true;
      callback();
    });

    const value: CustomAnalyticsBrowserContextValue = {
      analytics,
      enabled,
      currentPageIdRef,
      pageMapper,
      section,
      postponeInitialization,
      enqueueAction,
      setCurrentPageId: (pageId) => {
        currentPageIdRef.current = pageId;
        setCurrentPageId(pageId);
      },
    };

    useLayoutEffect(() => {
      if (analytics && user?.id) {
        enqueueAction(() => {
          analytics.identify(user.id, {
            is_admin: Boolean(user.admin),
            is_neon_tech_account: user.email?.endsWith('@neon.tech'),
            plan: user.plan,
            user_id: user.id,
          });
        });
      }
    }, [analytics, enqueueAction, user]);

    return (
      <AnalyticsContext.Provider value={value}>
        {children}
      </AnalyticsContext.Provider>
    );
  },
);
AnalyticsProvider.displayName = 'AnalyticsProvider';
