import { type ReactNode, useEffect } from 'react';
import TagManager from 'react-gtm-module';

import {
  AnalyticsProvider as SegmentAnalyticsProvider,
  useAnalyticsContext,
} from '@neondatabase/analytics';
import { useEventCallback } from '@neondatabase/glow/hooks/useEventCallback';

import { AUTH_SECTION_ID } from './pageIds';

export const AnalyticsProvider = ({
  segmentKey,
  gtmKey,
  children,
}: {
  segmentKey: string;
  gtmKey: string;
  children: ReactNode;
}) => {
  useEffect(() => {
    if (gtmKey && gtmKey !== '') {
      TagManager.initialize({
        gtmId: gtmKey,
      });
    }
  }, [gtmKey]);

  return (
    <SegmentAnalyticsProvider
      enabled
      segmentKey={segmentKey}
      section={AUTH_SECTION_ID}
      pageMapper={{}}
    >
      {children}
    </SegmentAnalyticsProvider>
  );
};

export const useAnalytics = () => {
  const { analytics, section } = useAnalyticsContext();

  const trackPage = useEventCallback((pageId: string, isError: boolean) => {
    const name = `${section}_${pageId}${isError ? '_error' : ''}`;

    analytics?.page(section, name);

    return undefined;
  });

  return {
    trackPage,
  };
};
